<!-- src/views/Login.vue -->
<template>
  <div class="login">
    <el-avatar :size="60" src="https://empty" @error="errorHandler">
      <img src="@/assets/logo.png"/>
    </el-avatar>
    <div class="jiange"></div>
    <el-button class="fastlogin" type="primary" @click="loginWithWeChat">登陆</el-button>
  </div>
</template>
<style>
.login{display: flex; align-items: center;flex-direction: column; margin-top: 100px;}
.fastlogin{ width: 200px; margin-top: 50px;}
.jiange{height: 50px;}
</style>
<script>
export default {
  methods: {
    loginWithWeChat() {
      // 跳转到微信授权页面
      const appid = 'wx7a8cd419ff561571'; // 你的微信AppID
      const redirectUri = encodeURIComponent('https://jinbo.cmgn.cc/getCode'); // 回调URL
      const state = 'random_state_value'; // 可设置为随机值，防止CSRF攻击
      
      // 拼接微信授权链接
      const wechatAuthUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;

      // 跳转到微信授权页面
      window.location.href = wechatAuthUrl;
    }
  }
};
</script>
