<template>
  <div>
 <!-- 图片和签名合并显示 -->
 <div style="height:30px;"></div>
    <div
      class="hetong"
      ref="imageWrapper"
      :style="isFullscreen ? fullscreenStyle : {}"
     
      @click="showPreview"
    >
    <!-- @click="toggleFullscreen"-->
      <canvas  ref="imageCanvas" width="800" height="1200" ></canvas>
    </div>
   <el-dialog :visible.sync="previewVisible" width="80%">
      <img :src="imageSrc" style="width: 100%;" />
    </el-dialog>
    <!-- 签名区域 -->
    <div class="canvas-container">
      <canvas
        ref="signatureCanvas"
        @mousedown="startDrawing"
        @mousemove="draw"
        @mouseup="stopDrawing"
        @mouseleave="stopDrawing"
        @touchstart.prevent="startDrawing"
        @touchmove.prevent="draw"
        @touchend.prevent="stopDrawing"
        width="300"
        height="100"
        style="border: 1px solid black; margin-top:5px;"
      ></canvas>
    </div>

    <div class="buju-qianming">
        <el-button type="info" @click="clearCanvas">清除签名</el-button>
        <el-button type="warning" @click="mergeSignatureToImage">确认签名</el-button>
        
    </div>

    <div class="buju-sumbit">
        <el-button class="button-submit" type="primary" :disabled="isButtonDisabled"  @click="saveSignature">上传合同</el-button>
    </div>
   
       <div style="padding-bottom:50px;"></div>
            <!-- <ToolBar></ToolBar> -->
  </div>
  
</template>

<script>
// import ToolBar from '@/components/Toolbar.vue'
import api from '../../api';
export default {
  components:{
      // ToolBar
   },
  data() {
    return {
      isButtonDisabled:false,
      orderId:"",
      isDrawing: false,
      lastX: 0,
      lastY: 0,
      ctx: null,
      signatureWidth: 500,
      signatureHeight: 200,
      isFullscreen: false, // 用于跟踪是否处于全屏模式
      imageSrc: "", // 指定图片路径
      hasSigned: false, // 新增变量：跟踪用户是否签名
       previewVisible: false,  // 控制是否显示图片组件
    };
  },
  computed: {
    // 定义全屏样式
    fullscreenStyle() {
      return {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        zIndex: "1000",
        backgroundColor: "#fff",
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      };
    },
  },
  mounted() {
    this.orderId = this.$route.query.order_id;
    this.init();
  },
  methods: {
     async init() {
        try {
          const response = await api.post('/api/order/contract?order_id='+this.orderId);
          this.imageSrc=response.data;
          this.setupCanvas();
          this.loadImage(); // 加载指定图片
          this.scrollToBottom();
        } catch (error) {
          this.$message.error('合同加载失败请联系管理员');
        }

    },
    showPreview() {
      this.previewVisible = true;
    },
    scrollToBottom() {
      const container = this.$refs.imageWrapper;
      container.scrollTop = container.scrollHeight;
    },
    // 初始化签名区域
    setupCanvas() {
        const signatureCanvas = this.$refs.signatureCanvas;
        this.ctx = signatureCanvas.getContext("2d");
        this.ctx.strokeStyle = "#000";
        this.ctx.lineJoin = "round";
        this.ctx.lineCap = "round";
        this.ctx.lineWidth = 3;

        // 添加浅色提示文字
        this.ctx.font = "16px Arial";
        this.ctx.fillStyle = "rgba(0, 0, 0, 0.3)"; // 设置浅色
        this.ctx.textAlign = "center"; // 文字居中对齐
        this.ctx.fillText("请在此处签名", signatureCanvas.width / 2, signatureCanvas.height / 2);
    },

    // 获取签名区域的鼠标坐标
    getEventPosition(event) {
      const canvas = this.$refs.signatureCanvas;
      const rect = canvas.getBoundingClientRect();
      let x, y;

      if (event.touches && event.touches.length > 0) {
        x = event.touches[0].clientX - rect.left;
        y = event.touches[0].clientY - rect.top;
      } else {
        x = event.clientX - rect.left;
        y = event.clientY - rect.top;
      }

      return { x, y };
    },

    // 签名开始
    startDrawing(event) {
        if (!this.hasSigned) {
            // 清除提示文字（仅执行一次）
            const signatureCanvas = this.$refs.signatureCanvas;
            this.ctx.clearRect(0, 0, signatureCanvas.width, signatureCanvas.height);
            this.hasSigned = true; // 标记用户已签名
        }

        this.isDrawing = true;
        const { x, y } = this.getEventPosition(event);
        this.lastX = x;
        this.lastY = y;
        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
    },

    // 绘制签名
    draw(event) {
      if (!this.isDrawing) return;
      const { x, y } = this.getEventPosition(event);
      this.ctx.lineTo(x, y);
      this.ctx.stroke();
      this.lastX = x;
      this.lastY = y;
    },

    // 签名结束
    stopDrawing() {
      this.isDrawing = false;
      this.ctx.closePath();
    },

    // 清除签名区域
    clearCanvas() {
        const signatureCanvas = this.$refs.signatureCanvas;
        this.ctx.clearRect(0, 0, signatureCanvas.width, signatureCanvas.height);

        // 重新加载提示文字
        this.ctx.font = "16px Arial";
        this.ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
        this.ctx.textAlign = "center";
        this.ctx.fillText("请在此处签名", signatureCanvas.width / 2, signatureCanvas.height / 2);

        this.loadImage(); // 重新加载背景图片
    },

    //加载图片到 imageCanvas
    loadImage() {
      const imageCanvas = this.$refs.imageCanvas;
      const imageCtx = imageCanvas.getContext("2d");
      const img = new Image();
      // img.crossOrigin = 'anonymous';  // 允许跨域请求
      img.src = this.imageSrc;

      img.onload = () => {
        imageCtx.drawImage(img, 0, 0, imageCanvas.width, imageCanvas.height);
      };
    },



    

    // 合并签名到图片上
    mergeSignatureToImage() {
           if (!this.hasSigned) {
                // 如果用户未签名，跳过合并操作
                console.log("没有签名，无法确认签名");
                return;
            }

            const imageCanvas = this.$refs.imageCanvas;
            const imageCtx = imageCanvas.getContext("2d");
            const signatureCanvas = this.$refs.signatureCanvas;

            // 获取图片和签名的宽高
            const imageHeight = imageCanvas.height;
            
            // 设置签名的大小（可以根据需求调整）
            const targetSignatureWidth = 100; // 设置绘制的签名宽度
            const targetSignatureHeight = 50; // 设置绘制的签名高度
            
            // 计算签名的绘制位置 (左下角)
            const x = 140; // 10px padding from left
            const y = imageHeight - targetSignatureHeight-140; // 10px padding from bottom

            // 将签名绘制在图片的左下角并缩放到指定大小
            imageCtx.drawImage(
                signatureCanvas,
                0, 0, signatureCanvas.width, signatureCanvas.height, // 源签名区域
                x, y, targetSignatureWidth, targetSignatureHeight // 目标位置和大小
            );
             // 合并后重置签名状态
            this.hasSigned = false;
    },

    // 保存图片并上传
    saveSignature() {
      this.isButtonDisabled = true; // 提交时禁用按钮
      const imageCanvas = this.$refs.imageCanvas;
      const imageData = imageCanvas.toDataURL("image/png");

      // 上传图像
      this.uploadSignature(imageData);
    },

    // 上传签名
    async uploadSignature(imageData) {

        try {
          const response = await api.post('/api/order/imagebase64?order_id='+this.orderId,{ file: imageData });
          if (response.code==200) {
            this.$message.success('上传成功,等待审核');
            setTimeout(() => {
                this.isButtonDisabled =false;
                this.$router.push('/');
            }, 2000); // 2000 毫秒 = 2 秒
          } else {
            this.isButtonDisabled =false;
            this.$message.error(response.msg);
          }
        } catch (error) {
          this.isButtonDisabled =false;
          this.$message.error('合同加载失败请联系管理员');
        }
    },

    // 切换全屏模式
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    }
  }
};
</script>

<style scoped>
.button-submit{ width: 200px; margin-top: 50px;}
.buju-qianming{display: flex;justify-content: space-around;}
.buju-sumbit{display: flex; justify-content: space-around;}
.hetong{  width: 300px; margin: auto; height: 400px; overflow: scroll; border: 1px solid black;}
/* 容器样式 */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* canvas容器样式 */
.canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* 控制按钮样式 */
.controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  margin-right: 10px;
}
</style>
