<template>
  <div class="bg">
    
      <div class="userInfo">
         <div class="user-info">
            <div><img class="avatar" :src="avatar"></div>
            <div class="nickname">{{nickname}}</div>
         </div>
      </div>
      <div class="outlogin" @click="logout">
         <div class="outlogin-ico">
            <div  class="out"><svg t="1725684238722" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4262" width="30" height="30"><path d="M948.7 492.6L811.5 321.2c-5.7-5.7-17.1-11.4-28.6-11.4h-11.4c-11.4 5.7-22.9 17.1-22.9 34.3V447H474.2c-40 0-68.6 28.6-68.6 68.6s28.6 68.6 68.6 68.6h274.4V687c0 17.1 11.4 28.6 22.9 34.3h11.4c11.4 0 22.9-5.7 28.6-11.4l137.2-171.5c5.7-11.5 5.7-28.6 0-45.8zM611.4 789.9H337c-40 0-68.6-28.6-68.6-68.6V304c0-40 28.6-68.6 68.6-68.6h274.4c40 0 68.6-28.6 68.6-68.6s-28.6-68.6-68.6-68.6h-343c-74.3 0-137.2 62.9-137.2 137.2v554.5c0 74.3 62.9 137.2 137.2 137.2h343c40 0 68.6-28.6 68.6-68.6 0-34.3-34.3-68.6-68.6-68.6z" fill="#00AA88" p-id="4263"></path></svg></div>
            <div class="small"  >退出登陆</div>
            <div class="user-row"><svg  t="1725684499028" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6263" width="30" height="30"><path d="M353.323071 246.407016L620.37222 510.637979l-265.320785 268.146133c-11.776208 11.775184-11.73425201 30.908964 0.091074 42.73429l0.001023 0c11.825326 11.82635 30.958082 11.867282 42.72815-2.930749L680.899758 535.559579c3.817955-4.273327 8.205892-9.321296 8.933463-12.045337 4.470825-11.112082 2.232854-24.76503301-6.710842-35.987632l-286.98213-286.98213c-11.875468-8.847505-31.096229-8.893554-42.922578 2.932796C341.393367 215.303624 341.439416 234.523361 353.323071 246.407016z" p-id="6264"></path></svg></div>
         </div>
      </div>


    <div style="padding-bottom:50px;"></div>
    <ToolBar :activeItem="'user'"></ToolBar>
  </div>
  
</template>
 <style>
 .user-row{float: right;}
 .outlogin-ico{}
 .out{float: left;}
 .avatar{border-radius: 100px; width: 50px; height: 50px;}
.nickname{ padding-top: 15px; padding-left:15px;}
.small{ float: left; padding-top: 5px; padding-left:15px;}
 .bg{background-color: #ececec; min-height: 900px; padding: 15px;}
 .userInfo{background-color: #ffffff; border-radius: 10px;box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);}
 .user-info{display:flex;justify-content: flex-start;padding: 15px;}
 .outlogin{overflow: hidden;  padding:15px;background-color: #ffffff; border-radius: 10px;box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3); margin-top: 15px;}
 
 </style>
<script>
import ToolBar from '@/components/Toolbar.vue'
export default {
   components:{
      ToolBar
    },
  data() {
    return {
      searchQuery: '',
      avatar:'',
      nickname:'',
    };
  },
  mounted() {
    const userinfo = localStorage.getItem('userinfo');
    if(userinfo){
        // 将 JSON 字符串解析为对象
        const userObject = JSON.parse(userinfo);
        this.avatar=userObject.avatar;
        this.nickname=userObject.nickname;
    }else{
       this.$router.push('/login');
    }
  },
  methods: {
    onSearch() {
      console.log('搜索内容:', this.searchQuery);
      // 在这里执行搜索逻辑
    },
     logout() {
      // 删除 localStorage 中的 token
      localStorage.removeItem('token');
      localStorage.removeItem('userinfo');
      // 跳转到登录页或者其他页面
      this.$router.push('/login');
    }
  }
};
</script>