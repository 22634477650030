// src/api.js
import axios from 'axios';
import router from './router';  // 引入 Vue Router 用于跳转

// 创建一个 axios 实例
const api = axios.create({
    baseURL: 'https://jinbo.api.cmgn.cc', // 你的 API 基础地址 /  https://jinbo.api.cmgn.cc
    timeout: 5000, // 请求超时时间
});

// 请求拦截器
api.interceptors.request.use(
    (config) => {
        // 在请求发送之前做一些处理，例如添加 token
        const token = localStorage.getItem('token');
        if (token) {
            //config.headers['Authorization'] = `Bearer ${token}`; // 如果用户已登录，附加 token
            config.headers['Token'] = `${token}`; // 如果用户已登录，附加 token
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
api.interceptors.response.use(
    (response) => {
        return response.data; // 返回响应数据
    },
    (error) => {
        if (error.response) {
            // 如果服务器返回 401，表示未授权，用户需要登录
            if (error.response.status === 401) {
                // 清除本地存储的 token
                localStorage.removeItem('token');
                localStorage.removeItem('userinfo');
                // 跳转到登录页面
                router.push('/login');
            }
        }
        return Promise.reject(error);
    }
);

// 导出封装的 axios 实例
export default api;
