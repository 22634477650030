<!-- src/pages/Home.vue -->
<template>
          <div class="bg">
            <div class="inlone">
              <div class="sumbit title">
                 合同信息
              </div>
              <el-form ref="form" :rules="rules" :model="form" label-width="110px">
                <el-form-item label="姓名"  prop="name">
                  <el-input v-model="form.name" placeholder="请输入姓名">
                  </el-input>
                </el-form-item>
                <!-- <el-form-item label="业务顾问">
                  <el-select v-model="form.consultant" placeholder="请选择业务顾问">
                    <el-option label="韩春龙" value="hanchunlong">
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="身份证号" prop="cardNo">
                  <el-input v-model="form.cardNo" placeholder="请输入身份证号">
                  </el-input>
                </el-form-item>
                <el-form-item label="车辆类型" prop="carType">
                  <el-select v-model="form.carType" placeholder="请选择车辆类型">
                    <el-option label="轿车" value="轿车">
                    </el-option>
                    <el-option label="MPV" value="MPV">
                    </el-option>
                    <el-option label="SUV" value="SUV">
                    </el-option>
                    <el-option label="面包车" value="面包车">
                    </el-option>
                    <el-option label="皮卡" value="皮卡">
                    </el-option>
                    <el-option label="其他" value="其他">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="车辆品牌" prop="carBrand">
                  <el-input v-model="form.carBrand" placeholder="请输入车辆品牌" >
                  </el-input>
                </el-form-item>
                <!---npm install element-china-area-data--->
                <el-form-item label="出发地" prop="fromCounty">
                  <el-cascader size="large" :options="fromAddress" v-model="form.fromCounty" 
                  @change="handleChange" placeholder="请选择省市区" style='width:100%'>
                  </el-cascader>
                </el-form-item>
                <el-form-item label="目的地" prop="toCounty">
                  <el-cascader size="large" :options="toAddress" v-model="form.toCounty" 
                  @change="handleChange" placeholder="请选择省市区" style='width:100%'>
                  </el-cascader>
                </el-form-item>
                <el-form-item label="发车电话" prop="startPhone">
                  <el-input type="number" v-model="form.startPhone" placeholder="请输入发车电话">
                  </el-input>
                </el-form-item>
                <el-form-item label="收车人姓名" prop="collectionName">
                  <el-input v-model="form.collectionName" placeholder="请输入收车人姓名">
                  </el-input>
                </el-form-item>
                <el-form-item label="收车人身份证" prop="collectionCardNo">
                  <el-input v-model="form.collectionCardNo" placeholder="请输入收车人身份证">
                  </el-input>
                </el-form-item>
                <el-form-item label="收车电话" prop="collectionPhone">
                  <el-input type="number" v-model="form.collectionPhone"  placeholder="请输入收车电话">
                  </el-input>
                </el-form-item>
                <el-form-item label="送车方式" prop="deliveryType">
                  <el-radio-group v-model="form.deliveryType">
                    <el-radio label="上门取车">
                    </el-radio>
                    <el-radio label="自送">
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="办事处地址" prop="officeCounty">
                  <el-cascader size="large" :options="officeAddress" v-model="form.officeCounty" 
                  @change="handleChange" placeholder="请选择省市区" style='width:100%'>
                  </el-cascader>
                </el-form-item>
                <el-form-item label="车牌类型" prop="licenseType">
                  <el-radio-group v-model="form.licenseType">
                    <el-radio label="车牌号">
                    </el-radio>
                    <el-radio label="车架号">
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="能源类型" prop="energyType">
                  <el-radio-group v-model="form.energyType">
                    <el-radio label="新能源">
                    </el-radio>
                    <el-radio label="油车">
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="车牌号" prop="enterCarNo">
                  <el-popover placement="bottom" width="400" v-model="carVisible" trigger="click">
                    <car-number :model-value.sync="form.enterCarNo" ref="carNumber" @fatherMethod="carVisibleChange">
                    </car-number>
                    <el-input slot="reference" v-model.trim="form.enterCarNo" placeholder="请输入车牌号"
                    @input="changeValue(form.enterCarNo)" />
                  </el-popover>
                </el-form-item>
                <el-form-item label="运输险/万" prop="insurance">
                  <el-input-number v-model="form.insurance" :step="5" @change="handleChange" :min="5"
                  label="请输入运输险额度">
                  </el-input-number>
                </el-form-item>
                 <el-form-item label="定金">
                  <el-input :disabled="true" v-model="form.deposit">
                  </el-input>
                </el-form-item>
                <el-form-item label="总价" prop="total">
                  <el-input type="number" v-model="form.total" placeholder="请输入总价">
                  </el-input>
                </el-form-item>

                <el-form-item label="备注">
                  <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.remark">
                  </el-input>
                </el-form-item>
                <!-- <el-form-item>
                <el-button type="primary" @click="onSubmit">立即创建</el-button>
                </el-form-item> -->
                <div class="sumbit">

                      <el-button class="sumbit-bg" :disabled="isButtonDisabled"   type="primary" @click="submitForm('form')">
                        下单
                      </el-button>
                </div>
              </el-form>
            </div>
            <audio hidden ref="audio" src="@/assets/success-logo.mp3" controls></audio>
            <!-- <div style="padding-bottom:50px;"></div>
            <ToolBar></ToolBar> -->
          </div>
          
</template>
<style>
.sumbit{display: flex;justify-content: space-around;}
.bg{ background-color: #606266; padding: 5px; padding-top:10px;}
.inlone{ border-radius: 10px;  background-color: #FFFFFF;}
.title{ font-size: 18px; padding:20px; color: #606266; }
.sumbit-bg{ width: 200px;}
</style>
<script>

import { provinceAndCityData, codeToText } from 'element-china-area-data'  //regionData
import CarNumber from '@/components/CarNumber.vue';
import api from '../../api';
// import ToolBar from '@/components/Toolbar.vue'


  export default {
    components:{
      CarNumber,
      //ToolBar
    },
    data() {
      const validateCardNo = (rule, value, callback) => {
        const idCardPattern = /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])\d{3}(\d|X)$/i;
        if (!value) {
          callback(new Error('请输入身份证号'));
        } else if (!idCardPattern.test(value)) {
          callback(new Error('身份证号格式不正确'));
        } else {
          callback();
        }
      };
      const validatePhone = (rule, value, callback) => {
        const phonePattern = /^1[3-9]\d{9}$/;  // 简单的手机号校验正则
        const landlinePattern = /^(\d{3,4}-?)?\d{7,8}$/;  // 固定电话校验正则
        if (!value) {
          callback(new Error('请输入手机号码'));
        } else if (!phonePattern.test(value) && !landlinePattern.test(value)) {
          callback(new Error('手机号码格式不正确'));
        } else {
          callback();
        }
      };
      return {
        isButtonDisabled: false, // 控制按钮是否禁用
        carVisible:false,
        fromAddress: provinceAndCityData,
        toAddress: provinceAndCityData,
        officeAddress:provinceAndCityData,
        form: {
          name:"",
          consultant:"",
          cardNo:"",
          carType:"",
          carBrand:"",
          fromCounty:"",
          toCounty:"",
          startPhone:"",
          collectionName:"",
          collectionCardNo:"",
          collectionPhone:"",
          deliveryType:"",
          officeCounty:"",
          licenseType:"",
          energyType:"",
          enterCarNo:"",
          insurance:5,
          deposit:0,
          total:"",
          remark:""
        },
        rules: {
          name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
            { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' }
          ],
          cardNo: [
            { required: true, message: '请输入身份证号', trigger: 'change' },
            { validator: validateCardNo, trigger: 'change' }
          ],
          collectionCardNo: [
            { required: true, message: '请输入收车人身份证号', trigger: 'change' },
            { validator: validateCardNo, trigger: 'change' }
          ],
          carType: [
            { required: true, message: '请选择车辆类型', trigger: 'change' }
          ],
          carBrand:[
            { required: true, message: '请输入车辆品牌', trigger: 'blur' }
          ],
          fromCounty:[
            { required: true, message: '请选择出发地', trigger: 'blur' }
          ],
          toCounty:[
            { required: true, message: '请选择目的地', trigger: 'blur' }
          ],
          startPhone:[
            { required: true, message: '请输入发车电话', trigger: 'blur' },
            { validator: validatePhone, trigger: 'blur' }
          ],
          collectionName:[
            { required: true, message: '请输入收车人姓名', trigger: 'blur' },
            { min: 2, max: 8, message: '长度在 2 到 8 个字符', trigger: 'blur' }
          ],
          collectionPhone:[
            { required: true, message: '请输入收车电话', trigger: 'blur' },
            { validator: validatePhone, trigger: 'blur' }
          ],
          deliveryType:[
            { required: true, message: '请选择送车方式', trigger: 'blur' }
          ],
          officeCounty:[
            { required: true, message: '请选择办事处地址', trigger: 'blur' }
          ],
          licenseType:[
            { required: true, message: '请选择车牌类型', trigger: 'blur' }
          ],
          energyType:[
            { required: true, message: '请选择能源类型', trigger: 'blur' }
          ],
          insurance:[
            { required: true, message: '请输入运输险额', trigger: 'blur' }
          ],
          total:[
            { required: true, message: '请输入总价', trigger: 'blur' }
          ]
          
        }
        
      }
    },
    methods: {
      submitForm(formName) {
          this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.isButtonDisabled = true; // 提交时禁用按钮
                  this.order();
              } else {
                  return false;
              }
          });
        },
        async order() {
            this.form.fromCounty=this.getCodeToText(this.form.fromCounty);
            this.form.toCounty=this.getCodeToText(this.form.toCounty);
            this.form.officeCounty=this.getCodeToText(this.form.officeCounty);
            try {
              const response = await api.post('/api/order/order', this.form);
              if(response.code==200){
                 this.$message.success('下单成功,等待审核');
                 this.$refs.audio.play();
                  setTimeout(() => {
                     this.isButtonDisabled=false;
                      this.$router.push('/');
                  }, 4000); // 2000 毫秒 = 2 秒
              }else{
                this.$message.error('下单失败请联系管理员');
                this.isButtonDisabled=false;
              }
            } catch (error) {
              this.$message.error('下单失败请联系管理员');
              this.isButtonDisabled=false;
            }

        },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        },
       // 获取省市区地址级联
       handleChange (value) {
           console.log(value)//打印出城市code值
           this.getCodeToText(value) //转换城市名称方法
        },
        //转换城市名称方法
        getCodeToText (val) {
              let cityNmae= "";
              switch (val.length) {
                case 1:
                  cityNmae+= codeToText[val[0]];
                  break;
                case 2:
                  cityNmae+= codeToText[val[0]] + "/" + codeToText[val[1]];
                  break;
                case 3:
                  cityNmae+=
                    codeToText[val[0]] +
                    "/" +
                    codeToText[val[1]] +
                    "/" +
                    codeToText[val[2]];
                  break;
                default:
                  break;
          }
          return cityNmae;
        },
        changeValue(item){
          this.$refs.carNumber.updateValue(item); // 调用子组件的方法
        },
        //车牌号校验
        carVisibleChange(){  
            let flag = false
            let xreg =
                /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
            let creg =
                /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
              // 判断车牌号长度
              let carNo = this.form.enterCarNo; // 假设是字符串
              if (carNo.length === 7) {
                flag = creg.test(carNo); // 普通车牌号
              } else if (carNo.length === 8) {
                flag = xreg.test(carNo); // 新能源车牌号
              }
            if (flag) {
              this.carVisible = false;
            }else{
               this.$message.error('请输入正确车牌号');
            } 
        },
    }
  }
</script>
