<template>
  <div class="bg-home">
    <div class="search">
        <el-input
          placeholder="搜索订单号或者托运人姓名"
          v-model="searchQuery"
          class="search-box"
          clearable
          prefix-icon="el-icon-search"
          @input="handleSearch"
        ></el-input>
        <!-- <el-button type="primary" @click="performSearch">搜索</el-button> -->
    </div>
    <el-empty v-if="data.length<=0" description="暂无订单信息"></el-empty>
    <!---订单详情--->
      <div class="orderInfo"  v-for="(item, index) in data" :key="index">
         <div class="order-info">
            <div>订单号：{{item.order_id}}</div>
            <div v-html="item.step_status_text"></div>
         </div>
         <div class="order-address">
            <div class="form_address">{{item.from_county.split('/')[1]}}</div>
            <div><img style="width:60px" src="@/assets/back.png"></div>
            <div class="form_address">{{item.to_county.split('/')[1]}}</div>
         </div>
         <div class="order-people">
            <div>车型：<p>{{item.license_type}}</p></div>
            <div>车牌号：<p>{{item.enter_car_no}}</p></div>
            <div>托运人：<p>{{item.name}}</p></div>
            <div>托运人电话：<p>{{item.start_phone}}</p></div>
         </div>
         <div class="order-more">
            <div><a :href="'/orderInfo?order_id='+item.order_id">查看详情</a></div>
         </div>
      </div>
    <!---订单详情--->
 
       <!-- <div v-if="loading" class="loading">Loading...</div> -->
    <div class="buy">
      <a href="/order">
        <el-button  type="primary" class="buy-order">我要下单</el-button>
      </a>
    </div>
   
    <div style="padding-bottom:50px;"></div>
    <ToolBar :activeItem="'order'" ></ToolBar>
  </div>
  
</template>
 <style>
 .loading{  margin: auto; text-align: center;}
 .search{ margin-bottom: 15px; width: 100%;}
 .buy{display:flex;justify-content: space-around;}
 .buy a{text-decoration: none; color: #ffffff; width: 200px;margin: auto; }
 .buy-order{ width: 200px; position:fixed;bottom: 100px; margin: auto; text-align: center; }
 .buy-order a{ text-decoration: none; color: #ffffff;}
 .order-more {padding: 15px; text-align: center;font-size: 16px;font-weight: bold;}
  .order-more  a{   text-decoration: none; color: #000000;}
 .order-people{display:flex;flex-direction: column; padding: 15px; border-bottom:#e5e5e5 1px solid ;}
  .order-people p{display:inline;}
 .form_address{font-size: 20px;font-weight: bold; padding-top: 15px;}
 .bg-home{background-color: #ececec; min-height: 900px; padding: 15px;}
 .orderInfo{background-color: #ffffff; border-radius: 10px; margin-bottom: 15px;}
 .order-info{display:flex;justify-content: space-between; border-bottom:#000000 1px solid ;padding: 15px;}
.order-address{display:flex;justify-content: space-around; padding-top: 10px;}
 </style>
<script>
import ToolBar from '@/components/Toolbar.vue'
import api from '../../api';
export default {
   components:{
      ToolBar
    },
  data() {
    return {
      searchQuery: '',
      data:[],
      loading: false, // 是否正在加载
      page: 1, // 当前加载的页码
      pageSize:10,
      totalPage: 1, // 总页数（假设总共5页数据）
    };
  },
  mounted() {
      // 监听窗口滚动事件
    window.addEventListener("scroll", this.handleScroll);
    // 初始化加载第一页数据
    this.init();
  },
  
  methods: {
     // 处理滚动事件
    handleScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // 判断是否滚动到底部，距离底部100px时加载更多
      if (scrollTop + windowHeight + 100 >= documentHeight) {
        if ( this.page >= this.totalPage) {
               return;
        }
        if(this.searchQuery){
            this.search();
        }else{
            this.init();
        }
      }
    },
     beforeDestroy() {
      // 组件销毁前移除滚动事件监听
      window.removeEventListener("scroll", this.handleScroll);
      },
    // 输入时处理搜索的回调
    handleSearch(value) {
         this.page=1;
         this.totalPage=1;
         this.data=[];
         if(value){
            this.search();
         }else{
            this.init();
         }
    },
    // 点击搜索按钮时执行的操作
    performSearch() {
         this.page=1;
         this.totalPage=1;
         this.data=[];
         if(this.searchQuery){
            this.search();
         }else{
            this.init();
         }
    },
    async init() {
            if ( this.page > this.totalPage) {
               return;
            }
            //this.loading = true;
            try {
              const response = await api.post('/api/order/orderList?page='+this.page+'&pageSize='+this.pageSize+'',{});
              this.page=this.page+1
              this.totalPage=response.data.last_page;
              this.data = [...this.data, ...response.data.data]
              //this.loading = false;
            } catch (error) {
               //this.$message.error('搜索失败请联系管理员');
               window.location.reload();
            }

      },
     async search() {
            if (this.page > this.totalPage) {
               return;
            }
            //this.loading = true;
            try {
              const response = await api.post('/api/order/search?page='+this.page+'&pageSize='+this.pageSize+'', {"keywords":this.searchQuery});
              this.page=this.page+1
              this.totalPage=response.data.last_page;
              this.data = [...this.data, ...response.data.data]
              //this.loading = false;
            } catch (error) {
              //this.$message.error('搜索失败请联系管理员');
               window.location.reload();
            }

      },
  }
};
</script>