<template>
  <div class="bg">

    <!---订单详情--->
      <div class="orderInfo">
         <div class="order-info">
            <div>订单号：{{result.order_id}}</div>
            <div v-html="result.step_status_text"></div>
         </div>
         <div class="order-address">
            <div class="form">{{result.from_county.split('/')[1]}}</div>
            <div><img style="width:60px" src="@/assets/back.png"></div>
            <div class="form">{{result.to_county.split('/')[1]}}</div>
         </div>

         <div class="order-more">
            <div class="see-hetong " v-if="status>=3"><el-button  @click="showPreviewDj">查看合同</el-button></div>
            <div  class="see-hetong " v-if="status>=2&&status!=3"> <a :href="'/deposit?order_id='+result.order_id"><el-button >查看定金</el-button></a></div>

            <div class="see-hetong " v-if="status==3"><a :href="'/contract?order_id='+result.order_id"><el-button  type="primary">签订合同</el-button></a></div>
            <div class="see-hetong " v-if="status==2"><el-button  @click="pay" type="primary">支付定金</el-button></div>
         </div>
      </div>
      <!-- 图片组件，绑定预览 -->
        <!-- 使用 el-dialog 来展示图片预览 -->
    <el-dialog :visible.sync="previewVisible" width="80%">
      <img :src="imageUrl" style="width: 100%;" />
    </el-dialog>

    <el-dialog :visible.sync="previewVisibleDj" width="80%">
      <img :src="imageUrlDj" style="width: 100%;" />
    </el-dialog>

    <!---订单详情--->
   <el-table
    :data="tableData"
    style="width: 100%;border-radius:10px;"
    class="no-header-table">
    <el-table-column
      prop="name"
      width="180">
    </el-table-column>

    <el-table-column
      prop="value"
     width="200">
    </el-table-column>



  </el-table>
  <div class="call">
      <div class="call-people">
        <div><svg t="1725686509638" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7313" width="30" height="30"><path d="M574.477 834.057C688.507 812.067 781.504 731.017 820.38 624H800c-17.673 0-32-14.327-32-32V432c0-17.673 14.327-32 32-32h20.38C774.61 274 653.816 184 512 184s-262.609 90-308.38 216H224c17.673 0 32 14.327 32 32v160c0 17.673-14.327 32-32 32H96c-17.673 0-32-14.327-32-32V432c0-17.673 14.327-32 32-32h31.892C176.329 233.599 329.964 112 512 112s335.67 121.599 384.108 288H928c17.673 0 32 14.327 32 32v160c0 17.673-14.327 32-32 32h-31.892C847.671 790.401 694.036 912 512 912c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64 30.557 0 56.113 21.416 62.477 50.057z" fill="#409EFF" p-id="7314"></path><path d="M832 464v96h64v-96zM128 464v96h64v-96z" fill="#FFFFFF" p-id="7315"></path></svg></div>
        <div class="call-people-text"><a  :href="'tel:'+phone" >联系客服</a></div>
      </div>
      <div class="pay" v-if="status==8" @click="payOver">
        <div><svg t="1725686586622" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8649" width="30" height="30"><path d="M704 397.92c-15.04-140.96-151.04-251.36-316.48-251.36-176 0-317.92 124.16-317.92 277.28a267.36 267.36 0 0 0 129.12 224c8.8 5.6-29.12 85.28-19.68 90.08s28.48-11.2 48.8-26.56 39.52-29.76 48-26.88a362.56 362.56 0 0 0 112 17.44 376.16 376.16 0 0 0 57.44-4.48c36.96 84.8 133.44 145.44 246.56 145.44a305.12 305.12 0 0 0 88.16-12.96c4.48-1.28 21.76 11.36 39.2 24.16s35.36 25.76 39.68 24c13.76-5.76-25.44-69.92-12.96-77.44 65.76-40.48 108.48-105.92 108.48-180-0.16-120-111.04-217.12-250.4-222.72z m-109.12 167.2a28 28 0 1 1 27.68-28 27.84 27.84 0 0 1-27.68 28z m-165.76 54.72a204.64 204.64 0 0 0 1.44 24.32 314.72 314.72 0 0 1-42.88 2.88 302.08 302.08 0 0 1-103.36-17.76c-3.2-1.12-14.4-4.64-20.48 0a265.28 265.28 0 0 0-32 32 142.4 142.4 0 0 0 8.96-38.4c1.12-10.24-14.56-17.6-17.76-19.68a220 220 0 0 1-98.08-178.4c0-122.88 117.6-222.4 262.72-222.4 135.36 0 246.88 86.72 260.96 198.24-124.48 17.44-219.52 108.96-219.52 219.2z m331.68-54.72a28 28 0 1 1 27.68-28 27.68 27.68 0 0 1-27.68 28z" fill="#409EFF" p-id="8650"></path><path d="M498.24 286.08a41.92 41.92 0 1 0 41.44 41.92 41.76 41.76 0 0 0-41.44-41.92zM276.96 286.08a41.92 41.92 0 1 0 41.6 41.92 41.6 41.6 0 0 0-41.6-41.92z" fill="#8DC81B" p-id="8651"></path></svg></div>
        <div class="pay-text" >支付尾款</div>
      </div>
  </div>
 
  <div class="step-status">
      <div calss="step-status-text">在途状态</div>
      <el-steps direction="vertical" :active="status_step">
        <el-step :title="item.createtime"   :description="item.step_status_text"  v-for="(item, index) in stepSattus" :key="index"></el-step>
        <!-- <el-step title="2024-08-30 12:49:46" description="订单确认"></el-step>
        <el-step title="2024-08-30 12:49:46" description="支付定金"></el-step>
        <el-step title="2024-08-30 12:49:46" description="签订合同"></el-step>
        <el-step title="2024-08-30 12:49:46" description="合同确认"></el-step>
        <el-step title="2024-08-30 12:49:46" description="已接车"></el-step>
        <el-step title="2024-08-30 12:49:46" description="到达目的地"></el-step>
        <el-step title="2024-08-30 12:49:46" description="支付尾款"></el-step>
        <el-step title="2024-08-30 12:49:46" description="完成"></el-step> -->
      </el-steps>
  </div>
    <!-- <div class="buy">
      <el-button  type="primary" class="buy-order"><a href="/order">我要下单</a></el-button>
    </div> -->
   
    <!-- <div style="padding-bottom:50px;"></div>
    <ToolBar :activeItem="'order'" ></ToolBar> -->
  </div>
  
</template>
 <style>
 .see-hetong{text-decoration: none; color: #ffffff;}
 .step-status-text{
   font-size: 15px; font-weight: bold;
 }
 
 .el-steps{ margin-top:15px;}
 .step-status{
    background-color: #ffffff; padding: 15px; margin-top:15px; 
 }
 .call-people-text{font-size: 15px; font-weight: bold; padding-top:5px;padding-left:5px;}
 .call-people-text a{ text-decoration: none; color: #000000;}
 .pay-text{font-size: 15px; font-weight: bold;padding-top:5px;padding-left:5px;}
 .call-people{display: flex; }
 .pay{display: flex;}
 .call{display: flex;justify-content: space-around; background-color: #ffffff; padding: 15px;}
.no-header-table .el-table__header-wrapper {
  display: none;
}
 .search{ margin-bottom: 15px;}
 .buy{display:flex;justify-content: space-around;}
 .buy-order{ width: 200px; position:fixed;bottom: 100px; margin: auto; text-align: center; }
 .buy-order a{ text-decoration: none; color: #ffffff;}
 .order-more {display: flex;justify-content: space-around; padding: 15px; text-align: center;font-size: 16px;font-weight: bold;}
  .order-more  a{   text-decoration: none; color: #000000;}
 .order-people{display:flex;flex-direction: column; padding: 15px; border-bottom:#e5e5e5 1px solid ;}
  .order-people p{display:inline;}
 .form{font-size: 20px;font-weight: bold; padding-top: 15px;}
 .bg{background-color: #ececec; min-height: 900px; padding: 15px;}
 .orderInfo{background-color: #ffffff; border-radius: 10px; margin-bottom: 15px;}
 .order-info{display:flex;justify-content: space-between; border-bottom:#000000 1px solid ;padding: 15px;}
.order-address{display:flex;justify-content: space-around; padding-top: 10px;}

 </style>
<script>
/* global WeixinJSBridge */
// import ToolBar from '@/components/Toolbar.vue'
import api from '../../api';
export default {
   components:{
      // ToolBar
    },
  data() {
    return {
      status_step:1,
      status:1,//当前状态
      searchQuery: '',
      imageUrlDj: '',  // 图片地址
      previewVisibleDj: false,  // 控制是否显示图片组件
      orderId:"",
      tableData: [],
      result:[],
      stepSattus:[],
      phone:"",
    };
  },
  mounted() {
    this.orderId = this.$route.query.order_id;
    this.init();
    this.getPhone();
  },
  methods: {
    
     showPreviewDj() {
      this.previewVisibleDj = true;
    },
    async init() {
        try {
          const response = await api.post('/api/order/orderLink?order_id='+this.orderId);
          this.tableData=response.data.info;
          this.result=response.data.data;
          if(response.data.data.contract_img_signature!=""&&response.data.data.contract_img_signature!=null){
            this.imageUrlDj=response.data.data.contract_img_signature;
          }else{
            this.imageUrlDj=response.data.data.contract_img;
          }
          this.status=response.data.data.step_status;
          this.stepSattus=response.data.step;
           this.status_step=response.data.step.length;
        } catch (error) {
          //this.$message.error('获取信息失败请联系管理员');
          window.location.reload();
        }

    },
    async getPhone() {
        try {
          const response = await api.post('/api/user/getPhone');
          this.phone=response.data;
        } catch (error) {
          window.location.reload();
          //this.$message.error('搜索信息失败请联系管理员');
        }

    },
    async pay(){
      try {
          const response = await api.post('/api/order/pay?order_id='+this.orderId);
           console.log(response.data);
          if(response.code==200){
              if (typeof WeixinJSBridge !== "undefined") {
                 this.onBridgeReady(response.data);
              } else {
                 this.$message.error('请在微信中打开!');
              }
              //window.location.href = response.data;
          }else if(response.code==201){
              this.$message.success('支付成功');
              setTimeout(() => {
                      window.location.reload();
              }, 2000); // 2000 毫秒 = 2 秒
          }else if(response.code==202){
              this.$message.success('已支付请忽重复支付');
              window.location.reload();
          }
         
        } catch (error) {
          this.$message.error('支付失败');
        }
    },
    async payOver(){
      try {
          const response = await api.post('/api/order/payOver?order_id='+this.orderId);
          console.log(response.data);
          if(response.code==200){
              if (typeof WeixinJSBridge !== "undefined") {
                 this.onBridgeReady(response.data);
              } else {
                 this.$message.error('请在微信中打开!');
              }
          }else if(response.code==201){
              this.$message.success('支付成功');
              setTimeout(() => {
                      window.location.reload();
              }, 2000); // 2000 毫秒 = 2 秒
          }else if(response.code==202){
              this.$message.success('已支付请忽重复支付');
              window.location.reload();
          }
        } catch (error) {
          this.$message.error('支付失败');
        }
    },
    onBridgeReady(data) {
      // 确保 WeixinJSBridge 已经加载
      if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', () => this.invokeWeChatPay(data), false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', () => this.invokeWeChatPay(data));
          document.attachEvent('onWeixinJSBridgeReady', () => this.invokeWeChatPay(data));
        }
      } else {
        this.invokeWeChatPay(data);
      }
    },
    // 实际调用微信支付的逻辑
    invokeWeChatPay(data) {
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": data.appId,         // 公众号ID
        "timeStamp": data.timeStamp, // 时间戳，自1970年以来的秒数
        "nonceStr": data.nonceStr,   // 随机串
        "package": data.package,     // 预支付交易会话标识
        "signType": data.signType,   // 签名方式
        "paySign": data.paySign      // 签名
      }, (res) => {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          // 支付成功
          this.$message.success('支付成功');
          this.$router.push('/orderInfo');
        } else {
          // 支付失败或用户取消支付
          this.$message.error('支付失败或取消支付');
        }
      });
    },
    // 输入时处理搜索的回调
    handleSearch(value) {
      console.log('搜索输入内容:', value);
      // 可以在这里执行实时搜索或自动补全逻辑
    },
    // 点击搜索按钮时执行的操作
    performSearch() {
      console.log('搜索内容:', this.searchQuery);
      // 在这里添加搜索逻辑，比如向后端发起请求
    }
  }
};
</script>