<template>
  <div class="bg">
    
      <div class="dingjinheader">
          <div class="dingjinheader-text">
          小轿车运输协议定金单
          </div>
      </div>
      <div class="text-pay">
        <div>付款方：{{result.name}}</div>
        <div>收款方：金博运车</div>
        <div>日期：{{result.createtime}}</div>
      </div>
      <div class="text-pay">
        <div>姓名：{{result.name}}</div>
        <div>电话：{{result.start_phone}}</div>
        <div>身份证号：{{result.card_no}}</div>
      </div>
      <table class="tableclass"  border="1"  cellspacing="1"  cellpadding="5" align="center">
        <tr>
          <th>起运地</th>
          <th>到达地</th>
          <th>车型</th>
          <th>车牌号</th>
          <th>预付款</th>
        </tr>
        <tr>
          <td>{{result.from_county.split('/')[2]}}</td>
          <td>{{result.to_county.split('/')[2]}}</td>
          <td>{{result.car_brand}}</td>
          <td>{{result.enter_car_no}}</td>
          <td>{{result.deposit}}</td>
        </tr>
      </table>
    <div class="tishiwenzi header-text">1、必须如实填写车辆名称及车辆信息，并确保自己交运的车辆不涉及两抢、偷盗、套牌、走私等涉案不合法车辆，并能提供车辆的合法来源及证明;</div>
    <div class="tishiwenzi header-text-bottom">2、所托运的车辆内严禁夹带易燃、易爆、易腐蚀、放射性、剧毒、易碎等物品，以及国家文明规定的各项管制刀具、枪械、白粉、海洛因、麻古、摇头丸等;</div>
    <div class="tishiwenzi header-text-bottom">3、车内放置的物品必须保持车窗、车门、后备箱能正常关闭，并保证所载的物品不影响视线，可以确保安全装卸上下车，且车内所载的物品不能过于零散，贵重的物品需要包装好确保物品的完整;</div>
    <div class="tishiwenzi header-text-bottom">4、托运的车辆严禁夹带、刻意隐瞒贵重物品，车内如有其他物品请书面交接井双方签字予以确认，否则丢失损毁自负</div>
    <div class="tishiwenzi header-text-bottom">5、托运的车辆，到达目的地后需要提供有效证件方可取车;</div>
    <div class="tishiwenzi header-text-bottom">6、必须按照约定期限结算该次运输的相关费用，如运费有尾款的，需要在目的地交车前，必须结清所有费用。《民法典》第五百八十七条:给付定金的一方不履行债务或者履行债务不符合约定，致使不能实现合同目的的无权请求返还定金;收受定金的一方不履行债务或者履行债务不符合约定，致使不能实现合同目的的，应当双倍返还定金。</div>
    
    <div class="order-btn">
        <el-button type="primary" @click="copyText" class="order-btn">复制订单号</el-button>
    </div>
   
    <input type="text" hidden v-model="textToCopy" />
    <div style="padding-bottom:50px;"></div>
    <ToolBar :activeItem="'user'"></ToolBar>
  </div>
  
</template>
 <style>
 .order-btn{
   width: 300px; margin: auto; margin-top: 15px;
 }
 .header-text-bottom{ padding-top:5px;}
 .header-text{ padding-top:15px;}
 .tishiwenzi{font-size: 12px; }
.tableclass{font-size: 12px; width: 100%; margin-top: 15px;}
.tableclass tr{border-bottom:1px #000000 solid; text-align: center;}
.tableclass  tr td{border-bottom:1px #000000 solid; text-align: center;}
.tableclass  tr th{border-bottom:1px #000000 solid; text-align: center;}
 .text-pay{display: flex; font-size: 10px;justify-content: space-between; border-bottom:1px #000000 solid; line-height: 30px; height: 30px;}
 .dingjinheader{ width: 100%; border-bottom:1px #000000 solid;}
 .dingjinheader-text{font-size: 20px; color: #ff0303; text-align: center; width: 200px; margin: auto; padding-top: 50px;
padding-bottom: 15px;
 }
 .user-row{float: right;}
 .outlogin-ico{}
 .out{float: left;}
 .avatar{border-radius: 100px; width: 50px; height: 50px;}
.nickname{ padding-top: 15px; padding-left:15px;}
.small{ float: left; padding-top: 5px; padding-left:15px;}
 .bg{background-color: #ffffff; min-height: 900px; padding: 15px;}
 .userInfo{background-color: #ffffff; border-radius: 10px;box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);}
 .user-info{display:flex;justify-content: flex-start;padding: 15px;}
 .outlogin{overflow: hidden;  padding:15px;background-color: #ffffff; border-radius: 10px;box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3); margin-top: 15px;}
 
 </style>
<script>
import ToolBar from '@/components/Toolbar.vue'
import api from '../../api';
export default {
   components:{
      ToolBar
    },
  data() {
    return {
      textToCopy: '',
      searchQuery: '',
      avatar:'',
      nickname:'',
      orderId:"",
      result:[],
    };
  },
  mounted() {
    this.orderId = this.$route.query.order_id;
    this.init();
  },
  methods: {
    copyText() {
      const input = document.createElement('input');
      input.value = this.textToCopy; // 设置要复制的文本
      document.body.appendChild(input); // 添加input元素
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行复制操作
      document.body.removeChild(input); // 移除input元素
       this.$message({
          message: '复制成功',
          type: 'success'
        });
    },
    async init() {
        try {
          const response = await api.post('/api/order/orderInfo?order_id='+this.orderId);
          this.tableData=response.data.info;
          this.textToCopy=response.data.data.order_id;
          this.result=response.data.data;
          this.stepSattus=response.data.step;
        } catch (error) {
          this.$message.error('信息获取失败请联系管理员');
        }

    },
  }
};
</script>