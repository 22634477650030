// src/router/index.js

import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/index/HomePage.vue'
import OrderPage from '@/pages/order/OrderPage.vue'
import ContractPage from '@/pages/contract/ContractPage.vue'
import OrderInfoPage from '@/pages/orderInfo/OrderInfoPage.vue'
import UserPage from '@/pages/user/UserPage.vue'
import LoginPage from '@/pages/login/LoginPage.vue'
import GetCode from '@/pages/login/GetCode.vue'
import DepositPage from '@/pages/deposit/DepositPage.vue'
import OrderLinkPage from '@/pages/orderLink/OrderLinkPage.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history', // 使用HTML5的history模式
  routes: [
    // {
    //   path: '/',
    //   redirect: '/home'  // 默认重定向到/home
    // },
    {
      path: '/',
      meta:{title:"订单列表",requiresAuth: true},
      name: 'Home',
      component: Home,
   
    },
    {
      path: '/order',
      meta:{title:"金博下单",requiresAuth: true},
      name: 'OrderPage',
      component: OrderPage,
    },
    {
      path: '/contract',
      meta:{title:"签订合同",requiresAuth: true},
      name: 'ContractPage',
      component: ContractPage,
  
    },
    {
      path: '/orderInfo',
      meta:{title:"订单详情",requiresAuth: true},
      name: 'OrderInfoPage',
      component: OrderInfoPage,
    },
    {
      path: '/user',
      meta:{title:"我的",requiresAuth: true},
      name: 'UserPage',
      component: UserPage,
    },

    {
      path: '/login',
      meta:{title:"登陆"},
      name: 'LoginPage',
      component: LoginPage
    },
    {
      path: '/getCode',
      meta:{title:"登陆"},
      name: 'GetCode',
      component: GetCode
    },
    {
      path: '/deposit',
      meta:{title:"定金单"},
      name: 'DepositPage',
      component: DepositPage
    },
    {
      path: '/dispatch',
      meta:{title:"派遣单"},
      name: 'OrderLinkPage',
      component: OrderLinkPage
    }
    // 其他路由
  ]
});
// 全局路由守卫，检查是否需要登录
router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('token');  // 判断用户是否登录
  console.log(isLoggedIn);
  next();
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //     if (!isLoggedIn) {
  //         next('/login');  // 如果未登录，跳转到登录页面
  //     } else {
  //         next();  // 已登录，继续访问
  //     }
  // } else {
  //     next();  // 不需要登录的页面，继续访问
  // }
});
export default router;