// src/main.js

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'  // 引入 Element UI 的样式
import BootstrapVue from 'bootstrap-vue'

// 引入 Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.css'
// 引入 BootstrapVue CSS
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(ElementUI)
Vue.use(BootstrapVue)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,  // 挂载路由
  render: h => h(App),
}).$mount('#app')
