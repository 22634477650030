<template>
  <div>
    <h1 class="login-ing"></h1>
  </div>
</template>
<style>
.login-ing{text-align: center; width: 200px; margin: auto; padding-top: 100px;}
</style>
<script>
import api from '../../api';

export default {
  data() {
    return {
      code: null, // 用来保存微信返回的 code
    };
  },
  async created() {
    // 获取微信授权返回的 code
    this.code = this.$route.query.code; 

    if (this.code) {
      try {
        // 使用 code 请求服务器，获取 access_token 和用户信息
        const response = await api.post('/api/user/wxlogin', { code: this.code });
        const token = response.data.userinfo.token;
        // 保存 token 到 localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('userinfo', JSON.stringify(response.data.userinfo));
        // 跳转到首页或用户之前访问的页面
        this.$router.push('/');
      } catch (error) {
        console.error('WeChat login failed:', error);
      }
    } else {
      console.error('No code returned from WeChat');
    }
  }
};
</script>
